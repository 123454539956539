import * as React from 'react';

import s from './ServicesSection.module.scss';
import { NEW_SERVICES } from './config';

const ServicesSection: React.FC = () => {
  return (
    <section className={s.wrapper}>
      <div className={s.columns}>
        <div className={s.image} />

        <div className={s.services}>
          <div className={s.title}>Services</div>
          <ul className={s.list}>
            {NEW_SERVICES.map((service, index) => (
              <li className={s.list__item} key={index}>
                {service}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
