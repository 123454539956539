import * as React from 'react';

import s from './AboutWorkDoneSection.module.scss';

const AboutWorkDoneSection: React.FC = () => {
  return (
    <div className={s.wrapper}>
      <div className={s.title}>The financial analysis made simple!</div>
      <div className={s.logo} />
    </div>
  );
};

export default AboutWorkDoneSection;
