import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { About, Contacts, Main } from './pages';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/about" element={<About />} />
      <Route path="/contacts" element={<Contacts />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default App;
