import * as React from 'react';

import { ReactComponent as Consulting } from '../../../../styles/img/consulting.svg';

export const SERVICES = [
  {
    icon: (
      <Consulting style={{ width: '45px', height: '45px', fill: '#2185f5' }} />
    ),
    title: 'Services',
    services: [
      '– Financial statement analysis',
      '– Ratio analysis',
      '– Valuation services',
      '– Credit analysis',
      '– Risk management services',
      '– Forensic accounting',
    ],
  },
];

export const NEW_SERVICES = [
  <>
    <div style={{ fontWeight: 500 }}>Financial analysis and forecasting:</div>
    Providing in-depth financial analysis of&nbsp;a&nbsp;company&rsquo;s
    financial statements and using that information to&nbsp;create forecasts and
    projections for the future.
  </>,
  <>
    <div style={{ fontWeight: 500 }}>Budgeting and cost analysis:</div> Helping
    companies create budgets that align with their financial goals and analyzing
    costs to&nbsp;identify areas where savings can be&nbsp;realized.
  </>,
  <>
    <div style={{ fontWeight: 500 }}>Business valuation:</div> Assessing
    a&nbsp;company&rsquo;s worth through a&nbsp;variety of&nbsp;methods,
    including discounted cash flow analysis, multiples analysis, and asset-based
    valuation.
  </>,
  <>
    <div style={{ fontWeight: 500 }}>Mergers and acquisitions:</div> Advising
    companies on&nbsp;potential mergers or&nbsp;acquisitions and assisting with
    due diligence, valuation, and negotiation.
  </>,
  <>
    <div style={{ fontWeight: 500 }}>Risk management:</div> Identifying and
    evaluating potential risks to&nbsp;a&nbsp;company&rsquo;s financial
    performance and developing strategies to&nbsp;mitigate those risks.
  </>,
  <>
    <div style={{ fontWeight: 500 }}>Performance measurement:</div> Creating
    metrics to&nbsp;measure a&nbsp;company&rsquo;s financial performance and
    developing strategies to&nbsp;improve performance.
  </>,
  <>
    <div style={{ fontWeight: 500 }}>Capital structure optimization:</div>{' '}
    Advising on&nbsp;the optimal mix of&nbsp;debt and equity financing
    to&nbsp;maximize a&nbsp;company&rsquo;s value and minimize its cost
    of&nbsp;capital.
  </>,
  <>
    <div style={{ fontWeight: 500 }}>Financial modeling:</div> Creating
    sophisticated financial models to&nbsp;help companies make informed
    decisions about their future plans and investments.
  </>,
  <>
    <div style={{ fontWeight: 500 }}>Investor relations:</div> Helping companies
    build and maintain strong relationships with investors and analysts,
    including communicating financial results and providing guidance.
  </>,
  <>
    <div style={{ fontWeight: 500 }}>Strategic planning:</div> Assisting
    companies with long-term strategic planning, including identifying growth
    opportunities and potential threats to&nbsp;the business.
  </>,
];
