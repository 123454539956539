import * as React from 'react';

import s from './Footer.module.scss';

const Footer: React.FC = () => {
  return (
    <div className={s.wrapper}>
      <div className={s.column}>
        <div className={s.title}>About us</div>
        <div className={s.text}>
          Let&rsquo;s achieve financial goals and maximize return
          on&nbsp;investment together!
          <br />
          Contact&nbsp;us via our email!
        </div>
      </div>
      <div className={s.column}>
        <div className={s.title}>Contact us</div>
        <div className={s.text}>
          Phone:
          <a href="tel:+37064367020"> +370 643 67 020</a>
        </div>
        <div className={s.text}>
          Email:
          <a href="mailto:admin@onhold.lt"> admin@onhold.lt</a>
        </div>
        <div className={s.text}>
          Address:
          <a href=""> Naugarduko g. 98, Vilnius</a>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Footer);
