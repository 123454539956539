import * as React from 'react';

import { Map, Navigation, Footer } from '../../components';

import {
  WorkDoneSection,
  ServicesSection,
  InformationSection,
} from './sections';

const Main: React.FC = () => {
  return (
    <>
      <Navigation />
      <WorkDoneSection />
      <ServicesSection />
      <InformationSection />
      <Map />
      <Footer />
    </>
  );
};

export default React.memo(Main);
