import * as React from 'react';

import s from './BottomSection.module.scss';

const BottomSection: React.FC = () => {
  return (
    <div className={s.wrapper}>
      <div className={s.overlay} />
      <div className={s.icon} />
      <div className={s.text}>Better Now, Than later!</div>
    </div>
  );
};

export default BottomSection;
