import * as React from 'react';

import s from './BlueSection.module.scss';

const BlueSection: React.FC = () => {
  return (
    <div className={s.wrapper}>
      <div className={s.icon} />
      <div className={s.title}>Who we are</div>
      <div className={s.text}>
        Our team of financial analysts and advisors are highly skilled and
        knowledgeable in their respective fields, with years of experience in
        financial analysis and consulting. We provide ongoing training and
        professional development opportunities to ensure that our team stays
        up-to-date with the latest industry trends and best practices.
      </div>
    </div>
  );
};

export default BlueSection;
