import * as React from 'react';

import s from './WorkDoneSection.module.scss';

const WorkDoneSection: React.FC = () => {
  return (
    <section className={s.wrapper}>
      <div className={s.logo} />
      <div className={s.title}>The financial analysis made simple!</div>
      <div className={s.description}>
        OnHold is committed to delivering the highest quality financial analysis
        services to our clients. We use the latest tools and technologies,
        combined with our extensive industry expertise, to provide accurate and
        reliable financial analysis and advice.
      </div>
    </section>
  );
};

export default WorkDoneSection;
